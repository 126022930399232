import React from 'react';

import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';
import img1 from 'img/help/custom_props/session_list.png';
import img2 from 'img/help/custom_props/session_player.png';
import img3 from 'img/help/custom_props/filters_identification.png';
import { Information } from '../../components/information';

const Content = () => {
  return (
    <div>
      <p>
        If you identify users visiting your website in any way, you can pass that data to
        LiveSession. It will add another dimension to your research and provide you with valuable
        information about your visitor’s behavior. In LiveSession, you can define up to 50 custom
        properties for each session.
      </p>

      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To pass data to LiveSession, you have to install our tracking code first. You can learn how
        to do it in <Link href="/help/how-to-install-livesession-script/">this guide</Link>.
      </div>
      <SubTitle>Users identification</SubTitle>
      <p>
        The most common way to identify a user is to use their name or email address. You can use
        one or both strings, according to your needs:
      </p>

      <Code lang="javascript" style={{ marginBottom: 24 }}>
        {`__ls("identify", { 
    name: "Adam McKenzie", 
    email: "adam@goodstuffinc.com"
});`}
      </Code>

      <p>
        To pass additional parameters, add them as <Code inline>{`params: {key: value}`}</Code>:
      </p>

      <Code lang="javascript" style={{ marginTop: 32 }}>
        {`__ls("identify", { 
    name: "Adam McKenzie", 
    email: "adam@goodstuffinc.com",
    params: {
      account_id: "101", 
      plan: "premium" 
    }
});`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Remember to update sample values. You have to trigger the function on every page view as we
        do not store custom properties’ values in any way.
      </div>

      <Information color="warning" style={{ marginTop: 32, marginBottom: 32 }}>
        Custom properties are available from the Starter plan up. If you’d like to add custom
        properties and you’re on a Micro plan, consider{' '}
        <Link href="/pricing/">switching plans</Link>.
      </Information>

      <p className="mt-3">
        Sessions from identified users will be listed on the sessions list, along with their email
        addresses. From here, you will also be able to access all of the custom properties you
        passed to LiveSession:
      </p>

      <Image
        src={img1}
        lazy
        alt="Adding custom properties and identify users"
        title="Recorder session"
      />

      <p>
        You can also access custom properties directly from the session player. They are available
        in the right panel, under the “Properties” tab:
      </p>

      <Image
        src={img2}
        lazy
        alt="Adding custom properties and identify users"
        title="Result in session replay player"
      />
      <p>
        You can also use the user’s name, email address and custom parameters you passed as filters
        to search sessions matching your criteria:
      </p>

      <Image
        src={img3}
        lazy
        alt="Adding custom properties and identify users"
        title="Fliter sessions"
      />

      <SubTitle>Set custom properties without identifying users</SubTitle>

      <p>
        You can pass custom properties to LiveSession without identifying your users. To do so, use
        the
        <Code inline>__ls(&quot;setCustomParams&quot;, data)</Code> function. Here’s an example of
        the snippet you can use to update sessions’ parameters:
      </p>

      <Code lang="javascript">
        {`__ls("setCustomParams", {
 params: {
   order_id: "123-abc-def",
   plan: "premium",
 },
});
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Remember to update sample values. You have to trigger the function on every page view as we
        do not store custom properties’ values in any way.
      </div>
      <h5 style={{ marginBottom: '2rem', fontWeight: 800, fontSize: '1.2rem' }}>
        Custom Params Limitations
      </h5>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <td>
              <p dir="ltr">
                <strong>Name</strong>
              </p>
            </td>
            <td>
              <p dir="ltr">
                <strong>Type</strong>
              </p>
            </td>
            <td>
              <p dir="ltr">
                <strong>Maximum length</strong>
              </p>
            </td>
            <td>
              <p dir="ltr">
                <strong>Details</strong>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p dir="ltr">Name</p>
            </td>
            <td>
              <p dir="ltr">String</p>
            </td>
            <td>
              <p dir="ltr">128</p>
            </td>
            <td>
              <p dir="ltr">Displays user names in app</p>
            </td>
          </tr>
          <tr>
            <td>
              <p dir="ltr">Email</p>
            </td>
            <td>
              <p dir="ltr">String</p>
            </td>
            <td>
              <p dir="ltr">128</p>
            </td>
            <td>
              <p dir="ltr">Displays user email</p>
            </td>
          </tr>
          <tr>
            <td>
              <p dir="ltr">Params</p>
            </td>
            <td>
              <p dir="ltr">JSON object</p>
            </td>
            <td>
              <p dir="ltr">50 items</p>
            </td>
            <td>
              <p dir="ltr">
                Valid JSON object {`{`}param1: value, param2: value, ... {`}`}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p dir="ltr">param.key</p>
            </td>
            <td>
              <p dir="ltr">string</p>
            </td>
            <td>
              <p dir="ltr">64</p>
            </td>
            <td>
              <p dir="ltr">
                {`{`}
                <strong>key</strong>:value{`}`}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p dir="ltr">param.value</p>
            </td>
            <td>
              <p dir="ltr">string</p>
            </td>
            <td>
              <p dir="ltr">64</p>
            </td>
            <td>
              <p dir="ltr">
                {`{`}key:<strong>value</strong>
                {`}`}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5 style={{ margin: '2rem 0', fontWeight: 800, fontSize: '1.2rem' }}>Rate limiting</h5>

      <p>
        Calls to Record Session API (rs.livesession.io) for{' '}
        <Code inline>__ls(&apos;identify&apos;)</Code> are rate limited per-session to 5 requests
        per second and 10 request per minute. If these limits are exceeded, custom params won&apos;t
        be set.{' '}
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
  {
    link: '/help/how-to-check-if-my-tracking-script-works',
    title: 'How to check if my tracking script works',
    description: 'Check if your tracking code is installed correctly',
  },
];

const Wrapped = Article(Content);

export const frontmatter = {
  metaTitle: 'How to identify users and add custom properties?',
  metaDescription:
    'With LiveSession you can identify user by email and name. Check how to do that.',
  canonical: '/help/user-identification-custom-properties/',
};

export default () => (
  <Wrapped related={related} section="Developer" title={frontmatter.metaTitle} {...frontmatter} />
);
